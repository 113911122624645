import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";

import "./sidebar-tabs.css";

function LinkToAuthors(props) {
  const authors = props.authors.map((author, index) => (
    <span key={author.Name}>
      {index ? ", " : ""}
      <Link to={"../authors/" + author.ID}>{author.Name}</Link>
    </span>
  ));
  return <span>{authors}</span>;
}
function ResourceTile(props) {
  return (
    <div className="resource-tile">
      <div className="col img">
        <img
          src={props.thumbnailImg.URL}
          alt=""
          width={props.thumbnailImg.Width}
          height={props.thumbnailImg.Height}
        />
      </div>
      <div className="col text">
        <p>
          <Link to={"../" + props.id + "/"} reloadDocument>
            {props.name}
          </Link>
        </p>
        {props.authors && (
          <p className="author">
            <LinkToAuthors authors={props.authors} />
          </p>
        )}
      </div>
    </div>
  );
}
function TabContents(props) {
  if (props.tabData) {
    const tiles = props.tabData.map((resource) => {
      return (
        <ResourceTile
          key={resource.ID}
          id={resource.ID}
          name={resource.Title}
          thumbnailImg={resource.Thumbnail}
          authors={resource.Authors}
        />
      );
    });
    return <div>{tiles}</div>;
  } else {
    return (
      <div className="loading-tab">
        <Logo fill="#eee" />
      </div>
    );
  }
}

export default function SidebarTabs(props) {
  const [relatedTabData, setRelatedTabData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(props.relatedURL);
      const newTabData = await response.json();
      setRelatedTabData(newTabData.Links);
    };
    fetchData();
  }, [props.relatedURL]);

  return (
    <>
      <p className="sidebar-heading first">Related Demonstrations</p>
      <div className="SidebarTabsContent">
        <TabContents tabData={relatedTabData} />
      </div>
    </>
  );
}
