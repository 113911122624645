import { Link, useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";
import AllPageComponent from "../components/all-page-component/all-page-component";

function ResourcesListItem(props) {
  return (
    <li key={props.id}>
      <Link to={"../" + props.id}>{props.title}</Link>
    </li>
  );
}

export default function AllPage(props) {
  return (
    <main className="main all-page">
      <Helmet>
        <title>All Demonstrations | Wolfram Demonstrations Project</title>
      </Helmet>
      <AllPageComponent
        pageName="All Demonstrations"
        pageData={useLoaderData().Index}
        ListItemComponent={ResourcesListItem}
        showLinkToAll={true}
      />
    </main>
  );
}
