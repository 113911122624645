import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";
import ResourceTablePage from "../components/resource-table-page/resource-table-page";

export default function LatestPage() {
  const resources = useLoaderData().Resources;
  return (
    <>
      <Helmet>
        <title>Latest Demonstrations | Wolfram Demonstrations Project</title>
      </Helmet>
      <ResourceTablePage
        title="New & Updated"
        resources={resources}
        showLinkToAll={true}
      />
    </>
  );
}
