import { Link } from "react-router-dom";

export default function LinkToAll() {
  return (
    <div style={{ margin: "5rem 0 1rem" }}>
      <hr />
      <p style={{ margin: ".5em 0" }}>
        <Link to="../all">Alphabetical listing of all demonstrations &raquo;</Link>
      </p>
      <p style={{ margin: ".5em 0" }}>
        <Link to="../authors/">Alphabetical listing of all authors &raquo;</Link>
      </p>
    </div>
  );
}
