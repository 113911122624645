import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";

import TopicTree from "../components/topic-tree/topic-tree";

export default function TopicTreePage() {
  const pageData = useLoaderData();
  return (
    <>
      <Helmet>
        <title>Explore Topics | Wolfram Demonstrations Project</title>
      </Helmet>
      <TopicTree title="Explore Topics" data={pageData} showLinkToAll={true} />
    </>
  );
}
