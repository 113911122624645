import { Link, Form, Outlet, ScrollRestoration } from "react-router-dom";

import "./root.css";
import Logo from "../../components/Logo";

export default function Root(props) {
  return (
    <div className="site-root">
      <header>
        <div className="inner">
          <Logo />
          <h1>
            <Link to="">
              <span style={{ fontWeight: 400, textTransform: "uppercase" }}>
                Wolfram
              </span>{" "}
              Demonstrations Project
            </Link>
          </h1>
          <div className="right">
            <span style={{ fontWeight: 600 }}>13,000+</span> Open Interactive
            Demonstrations
            <br />
            <span className="makers">
              From the makers of{" "}
              <a href="https://www.wolfram.com/language/">Wolfram Language</a>{" "}
              and <a href="https://www.wolfram.com/mathematica/">Mathematica</a>
            </span>
          </div>
        </div>
      </header>
      <nav>
        <div className="inner">
          <Form action="search" method="get" className="search-form">
            <input type="submit" className="search-button" value="" />
            <input
              type="text"
              name="query"
              placeholder="Search"
              className="search"
              required
            />
          </Form>
          <ul>
            <li>
              <Link to="topics">Topics</Link>
            </li>
            <li>
              <Link to="new">Latest</Link>
            </li>
            <li>
              <a href={props.randomPageAPI}>Random</a>
            </li>
            {/* <li>
              <Link to="about">About</Link>
            </li> */}
          </ul>
          <ul className="right">
            <li>
              <a href={props.createNotebookDownload}>Authoring Notebook</a>
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />
      <ScrollRestoration />
      <footer>
        <div className="inner">
          <ul className="footer-top">
            <li>
              <a
                href="http://www.wolfram.com/language/"
                className="wolfram-language"
              >
                Wolfram Language
              </a>
            </li>
            <li>
              <a
                href="http://www.wolfram.com/language/"
                className="mathematica"
              >
                Mathematica
              </a>
            </li>
            <li>
              <a href="http://www.wolfram.com/wolfram-u/" className="wolfram-u">
                Wolfram U
              </a>
            </li>
            <li>
              <a href="http://community.wolfram.com/" className="community">
                Community
              </a>
            </li>
            <li>
              <a href="http://www.wolfram.com/resources/">More&nbsp;&raquo;</a>
            </li>
          </ul>
          <p className="footer-bottom">
            <span className="powered-by" style={{ marginRight: "7.7rem" }}>
              <a href="http://www.wolfram.com/products/">
                Powered by WOLFRAM TECHNOLOGIES
              </a>
            </span>
            <span className="policies">
              &copy; <span id="copyYear">{new Date().getFullYear()}</span>{" "}
              Wolfram Demonstrations Project &amp; Contributors &ensp;|&ensp;{" "}
              <a href="http://www.wolfram.com/legal/terms/wolfram-demonstrations-project.html">
                Terms of Use
              </a>
              &ensp;|&ensp;
              <a href="https://www.wolfram.com/legal/privacy/wolfram/">
                Privacy Policy
              </a>
            </span>
            <span class="feedback">
              <a
                class="feedbackBtn"
                href="https://resources.wolframcloud.com/forms/contact"
              >
                Give feedback&nbsp;»
              </a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  );
}
