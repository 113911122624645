import { Link } from "react-router-dom";
import "./resource-list.css";

function ResourceTile(props) {
  return (
    <div className="resource-tile">
      <Link to={props.pathPrefix + props.id + "/"}>
        <img
          src={props.thumbnail.URL}
          alt=""
          width={props.thumbnail.Width}
          height={props.thumbnail.Height}
        />
        <p>{props.name}</p>
      </Link>
    </div>
  );
}
export default function ResourceList(props) {
  const resources = props.resources.map((resource) => (
    <ResourceTile
      pathPrefix={props.pathPrefix}
      key={resource.ID}
      id={resource.ID}
      name={resource.Title}
      thumbnail={resource.Thumbnail}
    />
  ));
  return (
    <div className={"resource-list " + (props.showTitles ? "show-titles" : "")}>
      {resources}
    </div>
  );
}
