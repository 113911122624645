import { useState } from "react";
import ResourceList from "../resource-list/resource-list";
import LinkToAll from "../link-to-all/link-to-all";

import "./resource-table-page.css";

export default function ResourceTablePage(props) {
  const [showTitles, setShowTitles] = useState(true);

  return (
    <main className="main resource-table-page">
      <section className="section">
        <div className="inner">
          <h1>{props.title}</h1>
          <div className="page-controls">
            <p>
              {props.resources.length} demonstration
              {props.resources.length == 1 ? "" : "s"}
            </p>
            {props.resources.length > 0 && (
              <p>
                <label>
                  <input
                    type="checkbox"
                    checked={showTitles}
                    onChange={() => {
                      setShowTitles(!showTitles);
                    }}
                  />{" "}
                  Show Titles
                </label>
              </p>
            )}
          </div>
          <ResourceList
            resources={props.resources}
            pathPrefix="../"
            showTitles={showTitles}
          />
          {props.showLinkToAll && <LinkToAll />}
        </div>
      </section>
    </main>
  );
}
