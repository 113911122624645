import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";
import ResourceTablePage from "../components/resource-table-page/resource-table-page";

export default function SearchPage() {
  let pageName = "Search for: " + useLoaderData().PageName;
  let resources = useLoaderData().Resources;

  return (
    <>
      <Helmet>
        <title>{pageName} | Wolfram Demonstrations Project</title>
      </Helmet>
      <ResourceTablePage
        title={pageName}
        resources={resources}
        showLinkToAll={true}
      />
    </>
  );
}
