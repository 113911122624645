import { Navigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

export default function AuthorRedirect() {
  let [searchParams] = useSearchParams();

  const query = searchParams.get("author");

  return (
    <main>
      <LoadingSpinner />
      {query == null ? (
        <Navigate to="../" />
      ) : (
        <Navigate to={"../search?query=" + query} />
      )}
    </main>
  );
}
